@import "fonts";

body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

audio {
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.start-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #0e082d;
    z-index: 10;
}

.container {
    max-width: 500px;
    height: 200px;
    margin: auto;
    text-align: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #FFF;

    h1 {
      font-size: 30px;
    }
}

.start-btn {
    cursor: pointer;
    text-transform: uppercase;
    position: initial !important;
}

.push {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    left: 330px;
    padding: 0 30px;
    font-size: 16px;
    height: 54px;
    line-height: 50px;
    border-radius: 30px;
    background-color: #F1F3F4;
    border: none;
    transition: background-color .2s ease;
    &:focus {
        outline: none;
    }
    &:hover {
        background-color: #9da0ff;
    }
}

canvas {
    background-color: #0e082d;
}
